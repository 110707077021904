.breadcrumb {
    display: inline;
    list-style: none;
    width: 50%;
}

.breadcrumb>ol {
    list-style: none;
    padding-left: 0.1em;
    padding-top: 2.5px;
}

.breadcrumb li {
    cursor: pointer;
    display: inline-block;
    padding-right: 0.1vw;
}

.breadcrumb span, .breadcrumb i {
    font-family: arial;
    font-size: 1.4vh;
    font-family: 'Roboto';
    font-style: normal;
}

.breadcrumb li:hover span, .home-icon:hover span {
    color: #0070AD;
}

.home-icon {
    cursor: pointer;
    float: left;
}

.home-icon span {
    font-size: 2.5vh;
    text-align: center;
    vertical-align: middle;
}

@media (max-height: 780px) {
    .breadcrumb>ol {
        padding-top: 2px;
    }
    .breadcrumb span, .breadcrumb i {
        font-size: 2vh;
    }
    .home-icon span {
        font-size: 3.5vh;
    }
}
@media (max-height: 700px) {

    .breadcrumb span, .breadcrumb i {
        font-size: 2.1vh;
    }
    .home-icon span {
        font-size: 4.0vh;
    }
}

@media (max-height: 520px) {
    .breadcrumb span, .breadcrumb i {
        font-size: 2.5vh;
    }
    .home-icon span {
        font-size: 4.5vh;
    }
}