@font-face {
  font-family: 'icomoon';
  src: url("../assets/icons/icomoon.eot?iwx07u");
  src: url("../assets/icons/icomoon.eot?iwx07u#iefix") format("embedded-opentype"), url("../assets/icons/icomoon.ttf?iwx07u") format("truetype"), url("../assets/icons/icomoon.woff?iwx07u") format("woff"), url("../assets/icons/icomoon.svg?iwx07u#icomoon") format("svg");
  font-weight: normal;
  font-style: normal; }

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.icon-print:before {
  content: "\e900"; }

.icon-printer:before {
  content: "\e901"; }

.icon-arrow-left:before {
  content: "\e902"; }

.icon-arrow-right:before {
  content: "\e903"; }

.icon-baseline-mail_outline-24px:before {
  content: "\e904"; }

.icon-baseline-pause_circle_outline-24px:before {
  content: "\e905"; }

.icon-baseline-pause-24px:before {
  content: "\e906"; }

.icon-baseline-search-24px:before {
  content: "\e907"; }

.icon-baseline-send-24px:before {
  content: "\e908"; }

.icon-baseline-share-24px:before {
  content: "\e909"; }

.icon-baseline-timer-24px:before {
  content: "\e90a"; }

.icon-exit:before {
  content: "\e90b"; }

.icon-exit2:before {
  content: "\e90c"; }

.icon-ic_arrow_forward_18px:before {
  content: "\e90d"; }

.icon-lock:before {
  content: "\e90e"; }

.icon-logout:before {
  content: "\e90f"; }

.icon-next:before {
  content: "\e910"; }

.icon-outline-access_time-24px:before {
  content: "\e911"; }

.icon-outline-arrow_drop_down_circle-24px:before {
  content: "\e912"; }

.icon-outline-bar_chart-24px:before {
  content: "\e913"; }

.icon-outline-build-24px:before {
  content: "\e914"; }

.icon-outline-build-24px_Admin_panel:before {
  content: "\e915"; }

.icon-outline-check-24px:before {
  content: "\e916"; }

.icon-outline-close-24px:before {
  content: "\e917"; }

.icon-outline-feedback-24px:before {
  content: "\e918"; }

.icon-outline-home-24px:before {
  content: "\e919"; }

.icon-outline-insert_chart_outlined-24px:before {
  content: "\e91a"; }

.icon-outline-notifications-24px:before {
  content: "\e91b"; }

.icon-outline-pageview-24px:before {
  content: "\e91c"; }

.icon-outline-perm_identity-24px:before {
  content: "\e91d"; }

.icon-outline-remove_red_eye-24px:before {
  content: "\e91e"; }

.icon-outline-slideshow-24px:before {
  content: "\e91f"; }

.icon-outline-table_chart-24px:before {
  content: "\e920"; }

.icon-outline-thumb_down_alt-24px:before {
  content: "\e921"; }

.icon-outline-thumb_up_alt-24px:before {
  content: "\e922"; }

.icon-outline-view_compact-24px:before {
  content: "\e923"; }

.icon-outline-view_module-24px:before {
  content: "\e924"; }

.icon-previous:before {
  content: "\e925"; }

.icon-slideshow:before {
  content: "\e926"; }

.icon-thumb_down_alt-24px:before {
  content: "\e927"; }

.icon-thumb_up_alt-24px:before {
  content: "\e928"; }

.icon-time:before {
  content: "\e929"; }

.icon-Vector:before {
  content: "\e92a"; }

.icon-Vector-1:before {
  content: "\e92b"; }

.icon-Vector-2:before {
  content: "\e92c"; }

.icon-Vector-3:before {
  content: "\e92d"; }

.icon-Vector-4:before {
  content: "\e92e"; }

.icon-view1:before {
  content: "\e92f"; }

.icon-Viewr2:before {
  content: "\e930"; }
