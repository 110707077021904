.main-menu i {
  font-size: 24px;
  position: relative;
  display: table-cell;
  left: 22px;
}

.main-menu:hover,
nav.main-menu.expanded {
  width: 190px;
  overflow: visible;
}

.main-menu:hover #sidebar-option-container {
  left: 190px;
}

.main-menu:hover .nav-text {
  display: table-cell;
  left: 26px;
}

.main-menu:hover .logo-container {
  left: 28%;
}

.main-menu {
  background: #0070AD;
  position: fixed;
  overflow: visible;
  top: 0;
  bottom: 0;
  height: 100%;
  left: 0;
  width: 80px;
  -webkit-transition: width 0.05s linear;
  transition: width 0.05s linear;
  -webkit-transform: translateZ(0) scale(1, 1);
  z-index: 1000;
}

.top-list {
  padding: 100px 0;
  height: 100%;
  overflow: hidden;
}

.main-menu li {
  position: relative;
  padding-bottom: 24px;
  padding-top: 24px;
  height: 24px;
  display: block;
  cursor: pointer;
  width: 190px;
}

.main-menu li > a {
  position: relative;
  display: table;
  border-collapse: collapse;
  border-spacing: 0;
  color: #fff;
  font-family: arial;
  font-size: 16px;
  text-decoration: none;
  -webkit-transform: translateZ(0) scale(1, 1);
  -webkit-transition: all 0.1s linear;
  transition: all 0.1s linear;
}

.main-menu .nav-text {
  position: relative;
  display: none;
  padding-left: 5px;
  vertical-align: middle;
  text-align: left;
  font-family: "Roboto-Regular";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
}

.main-menu .nav-notifications-count {
  display: block;
  width: 15px;
  height: 15px;
  position: absolute;
  top: 0;
  right: -100%;
  z-index: 99;
  border-radius: 50%;
  background: red;
  text-align: center;
  font-size: 11px;
  line-height: 15px;
  font-weight: bold;
}

a:hover {
  text-decoration: none;
}

nav {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
}

nav ul,
nav li {
  outline: 0;
  margin: 0;
  padding: 0;
}

.main-menu li:hover > a {
  color: #88d5ed;
}

nav.main-menu li.active > a {
  color: #88d5ed;
}

.area {
  float: left;
  background: #e2e2e2;
  width: 100%;
  height: 100%;
}

#sidebar-option-container {
  overflow-y: auto;
  top: -9999px;
  padding: 60px 35px 20px 35px;
  border-top: 5px solid #e5e9ed;
  left: 80px;
  width: 400px;
  min-height: 440px;
  max-height: 100vh;
  position: absolute;
  background: #e5e9ed;
  transition: 0.05s ease-in-out all;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.sidebar-option-open {
  top: 0 !important;
}

#sidebar-option-close {
  color: black;
  top: 0;
  right: 0;
  position: absolute;
  font-size: 14px;
  padding: 5px;
  cursor: pointer;
}

#sidebar-option-container h5 {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  color: #0075b1;
}

#sidebar-option-container textarea {
  font-family: "Roboto-Regular";
  font-style: normal;
  font-weight: 500;
  border: none;
  background: none;
  display: block;
  margin-top: 27px;
  width: 100%;
  resize: none;
  min-height: 200px;
  font-size: 16px;
  line-height: 24px;
  background: white;
  padding: 50px;
}

#restricted::placeholder {
  text-align: left !important;
}

#sidebar-option-container textarea::placeholder {
  text-align: center;    
}

#sidebar-option-container textarea:focus {
  outline: none !important;
  border: none;
}

#sidebar-option-container p {
  margin-top: 40px;
  font-family: "Roboto-Regular";
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 21px;
  font-feature-settings: "pnum" on, "lnum" on;
}

.sidebar-send-button {
  font-family: "Roboto-Regular";
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.01em;
  color: #0070ad;
  float: right;
}

.notification-none {
  text-align: center !important;
  font-family: "Roboto";
  font-style: normal;
  font-weight: normal;
}

.notification-wrapper h4 {
  margin-top: 25px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.01em;
  color: #0070ad;
}

.notification-wrapper p {
  margin-top: 0 !important;
  font-style: normal !important;
  font-weight: 300 !important;
  font-size: 14px !important;
  line-height: 16px !important;
  letter-spacing: 0.01em !important;
}

.notification-wrapper .notification-date {
  font-size: 12px !important;
  font-style: italic !important;
}

.version-container {
  position: absolute;
  top: 92%;
  text-align: center;
  width: 100%;
  font-family: "Roboto-Regular";
  color: white;
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 14px;
  letter-spacing: 0.01em;
}

.logout-container {
  position: absolute;
  top: 82%;
  text-align: center;
  overflow: hidden;
  width: 100%;
  font-family: "Roboto-Regular";
  color: white;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.01em;
}

.logo-container {
  position: absolute;
  top: 0%;
  left: 19%;
  width: 60px;
  height: 70px;
  overflow: hidden;
}

.logo {
  height: 35px;
  width: 35px;
  fill: #ffff;
}
