.single-engagement-container {
    font-family: Roboto;
    float: left;
    width: 22%;
    padding-top: 16%;
    margin: 2% 2% 2% 2%;
    height: 1px;
    background: white;
    border-radius: 16px;
    position: relative;
    text-align: center;
    cursor: pointer;
    overflow: hidden;
    transition: 0.3s ease-in-out all;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    box-sizing: border-box;

}

.single-engagement-container:hover {
    -webkit-box-shadow: 0px 4px 30px -15px #000000;
    box-shadow: 0px 4px 30px -15px #000000;
}

.single-engagement-inner {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    max-width: 100%;
    max-height: 100%;
    transform: translateX(-50%) translateY(-50%)
}

.single-engagement-logo {
    width: 19%;
    padding-top: 19%;
    border-radius: 50%;
    background: red;
    margin: 0 auto 7% auto;
    position: relative;
}

.single-engagement-logo-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    font-style: normal;
    font-weight: 500;
    font-size: 1.4vw;
    line-height: 100%;
    color: white;
}

.engagement-tile-icon {
    width: 19%;
    margin: 0 auto 7% auto;
    height: 35%;
    padding: 2%;
}

.engagement-tile-icon>img {
    max-width: 100%;
    display: block;
    margin: 0 auto;
    height: 100%;
}

.single-engagement-title {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 1.4vw;
    overflow: hidden;
}

.single-engagement-title>p {
    margin: 0 5% 0 5%;
}

.single-engagement-subtext {
    font-style: normal;
    font-weight: 300;
    font-size: 1.1vw;
    line-height: 21px;
    margin-top: 6%
}

.circle-image {
    margin: 0 auto 7% auto;
    position: relative;
    width: 19%;
    border-radius: 50%;
    overflow: hidden;
    display: grid;
    place-items: center;
}

.circle-squer {
    width: 100%;
    padding-top: 100%;
    position: relative;
}

.circle-img {
    max-width: 70%;
    max-height: 70%;
    object-fit: contain;
    transform: translate(-50%, -50%);
    position: absolute;
    top: 50%;
    left: 50%;
}

@media only screen and (min-width: 992px) {
    /* 1st element in group*/
    .single-engagement-container:nth-child(4n+1) {
        margin-left: 0 !important;
    }

    /* 4th element in group*/
    .single-engagement-container:nth-child(4n) {
        margin-right: 0 !important;
    }

}

@media (max-width: 992px) {
    .single-engagement-container {
        width: 28%;
        margin: 2.5%;
        padding-top: 22%;
    }

    .single-engagement-logo-text {
        font-size: 2.0vw;
    }

    .single-engagement-title {
        font-size: 2.0vw;
    }

    .single-engagement-subtext {
        font-size: 1.5vw;
    }
}

@media (max-width: 768px) {
    .single-engagement-container {
        width: 40%;
        margin: 5%;
        padding-top: 35%;
    }

    .single-engagement-logo-text {
        font-size: 2.0vw;
    }

    .single-engagement-title {
        font-size: 2.0vw;
    }

    .single-engagement-subtext {
        font-size: 1.5vw;
    }
}