#loading-indicator-wrapper {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 150px;
    height: 150px;
    transform: translateX(-50%) translateY(-50%);
    z-index: 9999;
}

.loading-indicator-visible {
    visibility: visible;
}

.loading-indicator-hidden {
    visibility: hidden;
}
