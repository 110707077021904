@import '_fonts.scss';
@import '_bootstrap-variables.scss';
@import '../../node_modules/bootstrap/scss/bootstrap.scss';
@import '../../node_modules/datatables.net-bs4/css/dataTables.bootstrap4.min.css';


html, body{
    height: 100%;
    min-height:500px;
}
#root {
    height: 100%;
}

body > #root > div {
    height: 100% !important;
}

h4 {
    font-size: 2.6vh;
}

@page {
    size: auto;
  }