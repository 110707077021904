.wrap-full {
    width: 100%;
    height: 100%;
    overflow: hidden;
    float: left;
    background: white;
    border-radius: 16px;
    cursor: pointer;
    transition: 0.3s ease-in-out all;
    box-shadow: 1px 3px 6px 1px rgba(0, 0, 0, 0.25)
}

.frame-full {
    width: 100%;
    height: 100%;
    border: none;
}