.dashboard-tile-container {

    margin: 23px;
    float: left;
    width: 22%;
    padding-top: 16%;
    margin: 2% 2% 2% 2%;
    position: relative;
    text-align: center;
    cursor: pointer;
    transition: 0.3s ease-in-out all;
    
}
.dashboard-tile-content:hover {
    -webkit-box-shadow: 0px 4px 30px -15px #000000; 
    box-shadow: 0px 4px 30px -15px #000000;
}
.dashboard-tile-content {

    background: white;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 16px;
    position: absolute;
    z-index: 2;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.dashboard-tile-rect {

    position: absolute;
    top: 12%;
    left: -4%;
    background: red;
    width: 12%;
    height: 23%;
    border-radius: 18%;
}
.dashboard-tile-title {

    text-align: center;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 1.4vw;
    margin: 20% 5% 0 5%;
}
.dashboard-tile-subtext {

    text-align: center;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 300;
    font-size: 1.1vw;
    position: absolute;
    top: 70%;
    left: 35%;
}


@media only screen and (min-width: 992px) {

    .dashboard-tile-container:nth-child(4n+1) {
        margin-left: 0 !important;
    }
    
    /* 4th element in group*/
    .dashboard-tile-container:nth-child(4n) {
        margin-right: 0 !important;
    }    
   
}

@media (max-width: 992px) {

    .dashboard-tile-container {

        width: 28%;
        margin: 2.5%;
        padding-top: 22%;
    }
    .dashboard-tile-title {

        font-size: 2.0vw;
    }
    .dashboard-tile-subtext {

        font-size: 1.5vw;
    }
}
@media (max-width: 768px) {

    .dashboard-tile-container {

        width: 40%;
        margin: 5%;
        padding-top: 35%;
    }
    .dashboard-tile-title {

        font-size: 2.0vw;
       
    }
    .dashboard-tile-subtext {

        font-size: 2.0vw;
    }
}
