#chart-preview-side {

    width: 33.33%;
    float: left;
    order: 1;
    padding: 2% 0 0 0;
}
#chart-preview-main {

    float: right;
    width: 66.66%;
    min-height: 50vh;
    padding: 3% 1.5% 1.15% 1.5%;
    order: 2
}
#chart-preview-bottom {

    float: left;
    width: 100%;
    padding:0;
    order: 3
}
.chart-side-tile-preview-wrapper {

    position: relative;
    float: left;
    width: 50%;
    padding: 2.5%;
}

.chart-bottom-tile-preview-wrapper {

    position: relative;
    float: left;
    width: 16.66%;
    padding: 1%;
}

@media (max-width: 1024px) {

    #chart-preview-side {
        width: 100%;
        order: 2;
    }
    #chart-preview-main {

        min-height: 100vh;
        order: 1;
        width: 100%;
        padding: 4%;
    }
    .chart-bottom-tile-preview-wrapper {

        width: 50%;
        padding: 0 2% 4% 2%;
    }
}