// Bootstrap overrides

//
// Color system
//

$white:                           #fff;
$gray-100:                        #f0f3f5;
$gray-200:                        #c2cfd6;
$gray-300:                        #a4b7c1;
$gray-400:                        #869fac;
$gray-500:                        #678898;
$gray-600:                        #536c79;
$gray-700:                        #3e515b;
$gray-800:                        #29363d;
$gray-900:                        #151b1e;
$black:                           #000 !default;

$blue: #007AFF; // cg blue
$indigo:                          #6610f2 !default;
$purple:                          #6f42c1 !default;
$pink:                            #e83e8c !default;
$red: #f03861; // cg tech red
$orange:                          #f8cb00;
$yellow:                          #ffc107 !default;
$green: #40c190; // cg zest green
$teal:                            #20c997 !default;
$cyan: #1fb4d4; // cg vibrant blue
$rapport-main:                    #0070AD !default;


$colors: (
  blue:                           $blue,
  indigo:                         $indigo,
  purple:                         $purple,
  pink:                           $pink,
  red:                            $red,
  orange:                         $orange,
  yellow:                         $yellow,
  green:                          $green,
  teal:                           $teal,
  cyan:                           $cyan,
  white:                          $white,
  gray:                           $gray-600,
  gray-dark:                      $gray-800
);

$theme-colors: (
  primary:                        $rapport-main,
  secondary:                      $gray-300,
  success:                        $green,
  info:                           $cyan,
  warning:                        $yellow,
  danger:                         $red,
  light:                          $white,
  dark:                           $gray-800
);

// Options
//
// Quickly modify global styling by enabling or disabling optional features.

$enable-transitions:              true;
$enable-rounded:                  true;

// Body
//
// Settings for the `<body>` element.

$body-bg:                         #fff;
