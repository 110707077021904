.chart-tile-container {
    margin: 23px;
    float: left;
    width: 22%;
    padding-top: 16%;
    margin: 2% 2% 2% 2%;
    position: relative;
    cursor: pointer;
    transition: 0.3s ease-in-out all;
}

.chart-tile-preview-container {

    width: 96% !important;
    padding-top:16vh !important;

    .chart-tile-title {

        font-size: 0.65vw;
    }
}
 
.chart-tile-preview-container:hover .chart-tile-fullview-icon {
    display: none;
}

.chart-tile-preview-container:hover .chart-tile-text {
    display: block;
}


.chart-tile-content:hover {
    -webkit-box-shadow: 0px 4px 30px -15px #000000; 
    box-shadow: 0px 4px 30px -15px #000000;
}

.chart-tile-content {

    background: white;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 16px;
    position: absolute;
    z-index: 2;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.chart-tile-first-tag-rect {

    position: absolute;
    top: 12%;
    left: -4%;
    background: red;
    width: 12%;
    height: 23%;
    border-radius: 18%;
}

.chart-tile-second-tag-rect {

    position: absolute;
    top: 42%;
    left: -4%;
    background: red;
    width: 12%;
    height: 23%;
    border-radius: 18%;
}

.chart-tile-title {

    text-align: center;
    font-family: 'Roboto-Regular';
    font-style: normal;
    font-weight: 600;
    font-size: 1.1vw;
    letter-spacing: 0.01em;
    margin: 15% 5% 0 5%;
}

.chart-tile-icon {

    margin-top: 8%;
    height: 35%;
    padding: 2%;
}
.chart-tile-icon > img{

    max-width: 100%;
    display: block;
    margin: 0 auto;
    height: 100%;
}

.chart-tile-text {
    position: absolute;
    bottom: .3%;
    right: 5%;
    display: none;
    float: right;
    font-family: 'Roboto-Regular';
    font-style: normal;
    font-size: 0.8vw;
    color: #0070AD;
}

.chart-tile-fullview-icon {
    position: absolute;
    bottom: 10%;
    right: 12%;
    float: right;
    font-style: normal;
    font-size: 1.2vw;
    color: #0070AD;
}

.chart-tile-text:hover {
    color:  #ccc;
}


@media only screen and (min-width: 992px) {

    /* 1st element in group*/
    .chart-tile-container:nth-child(4n+1) {
        margin-left: 0 !important;
    }

    /* 4th element in group*/
    .chart-tile-container:nth-child(4n) {
        margin-right: 0 !important;
    }


}

@media (max-width: 1024px) {

    .chart-tile-preview-container {
        
        padding-top:25vh !important;

        .chart-tile-title {

            font-size: 2vw;
        }

    }
}
@media (max-width: 992px) {

    .chart-tile-container {

        width: 28%;
        margin: 2.5%;
        padding-top: 22%;
    }
    
    .chart-tile-preview-container {
        padding-top:20vh !important;
    }

    .chart-tile-title {

        font-size: 1.5vw;
    }
}
@media (max-width: 768px) {

    .chart-tile-container {

        width: 40%;
        margin: 5%;
        padding-top: 35%;
    }

    .chart-tile-preview-container {
        padding-top:20vh !important;
    }
    .chart-tile-title {

        font-size: 1.5vw;
    }
}

@media (max-height: 780px) {
    
    .chart-tile-preview-container {
        padding-top:15.5vh !important;
    }
}

@media (max-height: 670px) {
    
    .chart-tile-preview-container {
        padding-top:14.5vh !important;
    }
}