#master-board-engagements{

    width: 100%;
    float: left;
}
#master-board-dashboards {

    margin-top: 45px;
    width: 100%;
    float: left;
}