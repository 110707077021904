.charts-slideshow-wrapper {

    position: relative;
    width: calc(100vw - 81px);
    height: 100vh;
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    overflow: hidden;
}
.charts-slideshow-topbar {

    width: 100%;
    height: 70px;

    h3 {
        float: left;
        margin: 0;
        padding-left: 80px;
        padding-top: 10px;
        line-height: 50px;
        overflow: auto;
    }

    .charts-slideshow-options-wrapper {

        float: right;
        margin-right: 60px;
        padding-top: 20px;

        .charts-slideshow-option {
            float: left;
            position: relative;
            top: 100%;
            width: 24px;
            height: 24px;
            margin-left: 20px;
            cursor: pointer;
            text-align: center;
            line-height: 40px;
            font-size: 24px;

            i {
                position: absolute;
                top: 50%;
                left: 50%;
                font-style: normal;
                font-size: 24px;
                transform: translateX(-50%) translateY(-50%);
            }
        }

        .paused {

            color: red;
        }
    }
}
.charts-slideshow-content {

    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;

    & p {
        float: right;
    }

    iframe {
        width: calc(100vw - 85px);
        height: calc(100vh - 90px);
        transition: opacity 0.3s ease-in-out;
        border: none;
    }
}