.event-tracker-icons-container {
    text-align: right
}
.event-tracker-icons-container > div {
    display: inline;
    padding: 5px;
    cursor: pointer;
}
#event-tracker-table {
    font-size: 11px
}