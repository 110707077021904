#charts-container {

}
#charts-header > h4 {
    float:left;
}

.tags-container {

}

.tag-selected {
    font-size: 2.4vh;
    position: absolute;
    left: 10%;
    top: 2%
}

.tag-selected-name {
    padding-left: 15%;
}

.charts-tag-button {

    cursor: pointer;
    float: right;
    width: 10%;
    min-width: 120px;
    color: white;
    border-radius: 7px;
    margin: 5px;
    font-family: 'Roboto-Regular';
    font-style: 'normal';
    font-weight: 500;
    font-size: 1.7vh;
    line-height: 4vh;
    text-align: center;
    transition: 0.3s ease-in-out all;
    position: relative;
    
}

.charts-tag-button:hover {
    box-shadow: 6px 8px 10px 0px rgba(0, 0, 0, 0.25);
}

#charts-content {

    float: left;
    width: 100%;
    height: 100%;
}



@media (max-height: 520px) {

    .charts-tag-button {
        font-size: 2.2vh;
        line-height: 6vh;
    }
    
}


@media (max-height: 700px) {

    .charts-tag-button {
        font-size: 1.8vh;
        line-height: 5vh;
    }
    
}

@media (min-height: 1200px) {

    .charts-tag-button {
        
        font-size: 1vh;
        line-height: 3vh;
    }
    
}

@media only screen and (max-width: 992px) {

    #charts-header > h4 {
        width: 100%; 
        margin-bottom: 25px
    }
}

/* iPad */
@media only screen 
and (min-device-width : 768px) 
and (max-device-width : 1024px)  
{

    .charts-tag-button {
        font-size: 1.3vh;
        line-height: 4vh;
    }
}

