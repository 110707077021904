.main-container {
    float: left;
    height: 70px;
    width: 100%;
}

.content-container {
    padding-top: 20px;
    overflow: hidden;
    display: flex;
    height: 100%;
    justify-content: space-between;
}

.content-container i {
    font-size: 24px;
}

.content-container input {
    font-family: "Roboto";
    font-size: inherit;
    line-height: inherit;
    background: none;
    outline: transparent;
    border-width: 0 0 1px;
    border-color: #c4c4c4;
    padding-left: 2px;
    min-width: 50px;
    /* width: 20% */
}

#topbar-options-container {
    overflow: visible;
    height: 50px;
    width: 50%;
    display: flex;
    justify-content: flex-end;
}

.icon-link:hover > i,
.search-link:hover > i {
    color: #ccc;
}

.icon-link,
.search-link {
    cursor: pointer;
    color: rgb(67, 73, 83);
    padding-left: 20px;
    display: block;
    float: left;
    position: relative;
    z-index: 99;
}

.slideshow-icon-container i {
    font-size: 21px !important;
}

.preview-icon-container i {
    font-size: 21px !important;
}

.export-icon-container i {
    font-size: 21px !important;
}

.print-icon-container i {
    font-size: 21px !important;
}

.options-modal {
    cursor: default;
    position: absolute;
    top: 40px;
    right: 0;
    width: 240px;
    z-index: 99;
    display: none;
    border-top: 5px solid #0070ad;
    background: #eef2f4;
    padding: 20px 30px 30px 30px;
}

.options-modal.open {
    display: block !important;
}

.options-modal-entry {
    cursor: pointer;
    font-size: 16px;
    padding: 10px 0;
    user-select: none;
}

.options-modal-entry:hover {
    color: #ccc;
}

.options-modal-entry > i {
    margin-right: 20px;
}

.icon-link i {
    float: left;
}

.search-link i {
    float: right !important;
    font-size: 20px !important;
}

.icon-text {
    float: left;
    display: block;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 30px;
    padding-left: 7px;
}

.useful-container > span {
    color: #0070ad;
    font-size: 14px;
    font-family: "Roboto";
    font-style: normal;
    padding-top: 3.5px;
    padding-right: 20px;
}

.useful-container {
    display: inline-flex;
    justify-content: flex-end;
    height: 25px;
}

.divider {
    height: 24px;
    width: 25px;
    border-right: 2px solid #ccc;
}

.selected-thumb {
    color: #0070ad;
}

.single-engagement-logo {
    width: 19%;
    padding-top: 19%;
    border-radius: 50%;
    background: red;
    margin: 0 auto 7% auto;
    position: relative;
}

.single-engagement-logo-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    font-style: normal;
    font-weight: 500;
    font-size: 1.4vw;
    line-height: 100%;
    color: white;
}

.engagement-tile-icon {
    width: 19%;
    margin: 0 auto 7% auto;
    height: 35%;
    padding: 2%;
}

.engagement-tile-icon>img {
    max-width: 100%;
    display: block;
    margin: 0 auto;
    height: 100%;
}

.single-engagement-title {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 1.4vw;
    overflow: hidden;
}

.single-engagement-title>p {
    margin: 0 5% 0 5%;
}

.single-engagement-subtext {
    font-style: normal;
    font-weight: 300;
    font-size: 1.1vw;
    line-height: 21px;
    margin-top: 6%
}

.circle-image {
    margin: 0 auto 7% auto;
    position: relative;
    width: 19%;
    border-radius: 50%;
    overflow: hidden;
    display: grid;
    place-items: center;
}

.circle-squer {
    width: 100%;
    padding-top: 100%;
    position: relative;
}

.circle-img {
    max-width: 70%;
    max-height: 70%;
    object-fit: contain;
    transform: translate(-50%, -50%);
    position: absolute;
    top: 50%;
    left: 50%;
}
