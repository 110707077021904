.custom-outline-primary {
    border: 1px solid #007bff;  
    background-color: transparent;  
    color: #007bff;      
    transition: all 0.2s;
    cursor: pointer;
}

.custom-outline-primary:hover {
    background-color: #007bff;
    color: #fff; 
}

.btn-sm {
    padding: 0.25rem 0.5rem;
    font-size: 0.875rem;
    line-height: 1.5;
    border-radius: 0.2rem;
    width: 32px;
    height: 32px;
}

label {
    margin: 0 5px 0 0;
}

.table-img {
    width: 32px;
    height: 32px;
    margin: 5px;
}

.hidden {
    display: none;
}