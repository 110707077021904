/* ---------------------------------------------------
    TABLE
----------------------------------------------------- */
#statistics-table {
	font-size: 0.7em;
	table-layout: fixed;
	border-spacing: 0;
	white-space: normal !important;
    word-wrap: break-word;
}

#statistics-table thead tr:first-child th:first-child {
	border-top-left-radius: 15px !important;
}

#statistics-table thead tr:first-child th:last-child {
	border-top-right-radius: 15px !important;
}

#statistics-table thead tr {
	background-color: #0070ad;
	color: #fff;
	padding-top: 4px;
	padding-bottom: 4px;
}

#statistics-table .sorting:before,
#statistics-table .sorting:after,
#statistics-table .sorting_asc:before,
#statistics-table .sorting_asc:after,
#statistics-table .sorting_desc:before,
#statistics-table .sorting_desc:after {
	opacity: 1;
	font-size: 0.9rem;
}

#statistics-table .sorting_asc:before {
	color: #109cf1;
}
#statistics-table .sorting_asc:after {
	color: #fff;
}
#statistics-table .sorting_desc:before {
	color: #fff;
}
#statistics-table .sorting_desc:after {
	color: #109cf1;
}

#statistics-table .form-control-sm {
	height: 1.5em;
}

#statistics-table tbody tr {
	border-right: 1px solid #c6cbd4 !important;
}

#statistics-table tbody tr td {
	vertical-align: middle;
}

#statistics-table tbody tr:hover {
	background-color: #e1eefb;
}

#statistics-table tbody tr td:first-child {
	border-left: 5px solid transparent;
}

#statistics-table tbody tr:hover td:first-child {
	border-left: 5px solid #109cf1;
}

#statistics-table thead tr th:first-child,
#statistics-table tbody tr td:first-child {
	width: 15%;
}

#statistics-table thead tr th:not(:first-child) {
	text-align: center;
}

#statistics-table td.parent-sla {
	font-weight: bold;
}

.no-sort {
	pointer-events: none !important;
	cursor: default !important;
}

.no-sort::before,
.no-sort::after {
	display: none !important;
	visibility: hidden !important;
}

.score-cell {
	padding-left: 2px !important;
	padding-right: 2px !important;
	text-align: left;
}

.score-cell a {
	color: #444;
	margin-left: 8px;
}

.score-cell a.no-color {
	margin-left: 25%;
}

.score-cell a:hover {
	text-decoration: none;
}

.score-cell .fa-circle {
	margin-right: 3px;
	font-size: 0.5em !important;
}

.bg-green {
	color: #57c96a;
}

.bg-amber {
	color: #ef9a23;
}

.bg-red {
	color: #c70a0a;
}

.responsive-column-names {
	float: left;
}

.clear {
	width: 100%;
	clear: both;
}

/* ---------------------------------------------------
    MEDIA
----------------------------------------------------- */
@media all and (max-width: 1199px) {
	h4 {
		font-size: 1em;
	}

	.filter-option,
	.dropdown-item {
		font-size: 0.8em;
	}

	.export-button-group button {
		font-size: 0.85em;
	}

	#statistics-table {
		font-size: 0.7em;
		padding-left: 10px;
		padding-right: 10px;
		width: 100%;
	}

	#statistics-table td:not(.text-left),
	#statistics-table th {
		text-align: center !important;
		padding: 0;
	}

	.sorting:before,
	.sorting:after {
		display: none !important;
	}

	.score-cell .no-color {
		margin-left: 0px !important;
	}

	.score-cell .fa-circle {
		font-size: 0.5em !important;
		display: block;
		margin: -7px auto 1px auto;
	}

	.responsive-column-names {
		display: none !important;
	}
}

@media all and (max-width: 991px) {
	h4 {
		font-size: 0.9em;
	}

	.filter-option,
	.dropdown-item {
		font-size: 0.7em;
	}

	.export-button-group button {
		font-size: 0.85em;
	}

	#statistics-table {
		font-size: 0.6em;
	}

	#statistics-table th {
		word-spacing: 100vw;
	}
}

@media all and (max-width: 768px) {
	#statistics-table {
		font-size: 1em;
		padding-left: 10px;
		padding-right: 10px;
		width: 100% !important;
	}

	#statistics-table tr.odd td {
		background-color: #f3f2f2;
	}

	#statistics-table tbody tr td:first-child {
		width: inherit;
	}

	#statistics-table tbody tr:hover {
		background-color: inherit;
		border-left: 0;
	}

	.score-cell,
	.text-center,
	.text-left,
	#statistics-table th {
		font-size: 1em;
		padding: 0.75em !important;
	}

	.score-cell .fa-circle {
		font-size: 1em !important;
		display: inline;
		margin: 0;
	}

	.score-cell a,
	.no-color {
		text-align: center;
	}

	.no-color {
		margin-left: 0 !important;
	}

	.responsive-column-names {
		display: flex !important;
	}

	#statistics-table thead {
		border: none;
		clip: rect(0 0 0 0);
		height: 1px;
		margin: -1px;
		overflow: hidden;
		padding: 0;
		position: absolute;
		width: 1px;
	}

	#statistics-table tr {
		display: block;
		margin-bottom: 10px;
	}

	#statistics-table td {
		display: block;
		position: relative;
	}

	.score-cell a {
		text-align: right;
		display: inline;
		width: 120px;
	}

	#statistics-table a {
		position: absolute;
		right: 10px;
		font-size: 1em;
	}
}

@media all and (max-width: 575px) {
	#statistics-table {
		font-size: 0.8em;
		padding-left: 10px;
		padding-right: 10px;
		width: 100% !important;
	}
}
