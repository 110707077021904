#layout-container {
    background-color: #F6F8FA;
    overflow: auto;
    overflow-x: hidden;
}

#app-content {
    margin-left: 160px;
    margin-right: 60px;
    max-width: 90vw;
    height: 100%
}

.app-content-full-size {
    margin-left: 81px !important;
    max-width: 92vw !important;
}

.top-bar-wrapper {
    margin-left: 79px;
}

@media (max-width: 520px) {
    #app-content {
        margin-left: 100px;
        margin-right: 4.5%;
        height: 100%
    }
    .app-content-full-size {
        margin: 0;
    }
}