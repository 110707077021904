.signup__container {
  background: #e9e9e9;
  height: 100%;
  font-family: "Roboto-Regular"; }

.signup__overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #0070AD; }

.container__child {
  width: 100%;
  height: 100%;
  color: #fff;
  display: flex;
  align-items: center; }

.signup__thumbnail {
  position: relative;
  padding: 2rem;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  background-repeat: no-repeat;
  background-position: top center;
  background-size: cover; }

.thumbnail__logo,
.thumbnail__content,
.thumbnail__links {
  position: relative;
  z-index: 2; }

.thumbnail__logo {
  align-self: flex-start; }

.logo__shape {
  fill: #fff; }

.logo__text {
  display: inline-block;
  font-size: 0.8rem;
  font-weight: 700;
  vertical-align: bottom; }

.thumbnail__content {
  align-self: center; }

.heading--primary {
  font-size: 1.999rem; }

.heading--secondary {
  font-size: 1.414rem; }

.thumbnail__links {
  align-self: flex-end;
  width: 100%; }

.thumbnail__links a {
  font-size: 1rem;
  color: #fff; }
  .thumbnail__links a:focus, .thumbnail__links a:hover {
    color: rgba(255, 255, 255, 0.5); }

.signup__form {
  padding: 2.5rem;
  background: #fafafa; }

.label--form {
  font-size: 0.85rem;
  text-transform: uppercase;
  color: #ccc; }

.input--form {
  color: #111;
  background-color: transparent;
  border-top: 0;
  border-right: 0;
  border-left: 0;
  border-radius: 0;
  font-size: 0.91rem; }
  .input--form:focus {
    border-color: #111; }

.login-input--form {
  border-bottom: 0 !important;
  outline: none !important; }

.login-input--form:focus {
  outline: none !important;
  outline-width: 0 !important;
  box-shadow: none;
  -moz-box-shadow: none;
  -webkit-box-shadow: none; }

.input--form::placeholder {
  color: #a1a1a1; }

.btn--form {
  padding: 0.5rem 2.5rem;
  font-size: 0.95rem;
  font-weight: 600;
  color: #fff;
  background: #0070AD;
  border-radius: 0.7rem;
  cursor: pointer; }
  .btn--form:focus, .btn--form:hover {
    background: #0091e0;
    color: white; }

.signup__link {
  font-size: 0.8rem;
  font-weight: 600;
  text-decoration: underline;
  color: #999; }
  .signup__link:focus, .signup__link:hover {
    color: #787878; }

.bootstrap-col-nopadding {
  padding: 0 !important;
  margin: 0 !important; }

.row-container {
  height: 100%;
  width: 100%;
  margin: 0 !important; }

.roboto-dark-gray {
  font-family: "Roboto";
  color: #555; }

.form-control-no-block {
  display: inline !important; }

.field-border {
  border: 0.5px solid #869fac;
  margin-bottom: 6px; }

.checkbox-login-container {
  padding-top: 10px; }

.checkbox-login-input {
  height: 14px;
  width: 14px;
  margin-top: 7px; }

.checkbox-login-label {
  font-size: 14px; }

.noselect {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                  supported by Chrome, Opera and Firefox */ }
